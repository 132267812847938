import { forwardRef } from 'react';
import PropTypes from 'prop-types';
import { Icon } from '@iconify-icon/react';
import {
  CAMPAIGN_UPCOMING,
  CAMPAIGN_RUNNING,
  CAMPAIGN_COMPLETED,
  CAMPAIGN_STATUS_NAMES,
} from '@/campaigns/constants/Constants';
import {
  STATUS_NAMES,
  STATUS_DRAFT,
  STATUS_SCHEDULED,
  STATUS_UNPUBLISHED,
  STATUS_PUBLISHED,
} from '@/storychief/constants/Constants';
import Label from '@/storychief/components/Label';
import {
  CONTENT_ITEM_DRAFT,
  CONTENT_ITEM_SCHEDULED,
  CONTENT_ITEM_PUBLISHED,
  CONTENT_ITEM_STATUS_NAMES,
} from '@/contentTypes/constants/constants';
import UnconfirmedChangesIndicator from '@/storychief/components/UnconfirmedChangesIndicator';

const propTypes = {
  status: PropTypes.oneOf([
    STATUS_DRAFT,
    STATUS_SCHEDULED,
    STATUS_UNPUBLISHED,
    STATUS_PUBLISHED,
    CAMPAIGN_UPCOMING,
    CAMPAIGN_RUNNING,
    CAMPAIGN_COMPLETED,
    CONTENT_ITEM_DRAFT,
    CONTENT_ITEM_SCHEDULED,
    CONTENT_ITEM_PUBLISHED,
  ]),
  size: PropTypes.oneOf(['md', 'lg']),
  id: PropTypes.string,
  customLabel: PropTypes.node,
  icon: PropTypes.string,
  isCampaign: PropTypes.bool,
  isContentItem: PropTypes.bool,
  isReferralOutreach: PropTypes.bool,
  hasUnconfirmedChanges: PropTypes.bool,
  iconPosition: PropTypes.oneOf(['left', 'right']),
  onClick: PropTypes.func,
  isDisabled: PropTypes.bool,
};

const defaultProps = {
  status: null,
  id: null,
  icon: null,
  size: undefined,
  customLabel: null,
  isCampaign: false,
  isContentItem: false,
  isReferralOutreach: false,
  hasUnconfirmedChanges: false,
  iconPosition: 'left',
  onClick: undefined,
  isDisabled: undefined,
};

const StatusLabel = forwardRef(
  (
    {
      customLabel,
      status,
      id,
      icon,
      size,
      iconPosition,
      isCampaign,
      isContentItem,
      isReferralOutreach,
      hasUnconfirmedChanges,
      onClick,
      isDisabled,
    },
    ref,
  ) => {
    const statusName = getStatusName();

    function getStatusName() {
      if (customLabel) {
        return customLabel;
      }
      if (isCampaign) {
        return CAMPAIGN_STATUS_NAMES[status];
      }
      if (isContentItem) {
        return CONTENT_ITEM_STATUS_NAMES[status];
      }
      if (isReferralOutreach && status === STATUS_PUBLISHED) {
        return 'Sent';
      }
      return STATUS_NAMES[status];
    }

    function getVariant() {
      return statusName ? statusName.toLowerCase() : undefined;
    }

    return (
      <div className={`status-label status-label--icon-${iconPosition} relative`}>
        <Label
          id={id}
          variant={getVariant()}
          size={size}
          onClick={onClick}
          ref={ref}
          disabled={isDisabled}
        >
          <div className="status-label__row d-inline-flex flex-align-center">
            {icon && <Icon icon={icon} inline />}
            <span className="status-label__name">{statusName}</span>
            {hasUnconfirmedChanges && <UnconfirmedChangesIndicator size="sm" />}
          </div>
        </Label>
      </div>
    );
  },
);

StatusLabel.propTypes = propTypes;
StatusLabel.defaultProps = defaultProps;
StatusLabel.displayName = 'StatusLabel';

export default StatusLabel;
